/**
 * Mixins to build quick media queries
 * 1. screen-up - min-width only
 * 2. screen-down - max-width only
 * 3. screen-between - between min and max-width
 * 
 */
.header_video {
  overflow: hidden;
  object-fit: cover;
}
@media screen and (min-width: 576px) {
  .header_video {
    width: 100%;
  }
}

.header_wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

.header_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
}

@media screen and (max-width: 576px) {
  .site-logo {
    max-width: 150px;
  }
}
/* --------------------
   Hide titles

   page-node-120: Exhibiting at the show (page)
   page-node-1217: Connected Media News (page)
/* -------------------- */
.page-video-library h1,
.page-node-120 h1,
.page-node-1217 h1 {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.webform-client-form > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.form-item .description,
.webform-client-form .description {
  margin-top: 0.5rem;
  color: var(--clr-black-600, #737373);
}

.bg-danger .form-item .description,
.bg-danger .webform-client-form .description {
  margin-top: 1rem;
  color: var(--clr-black-200, #d9d9d9);
}

.bg-danger .form-item a,
.bg-danger .webform-client-form a {
  color: var(--clr-yellow-500, #f9c610);
}

/*  Form actions
/* -------------------- */
input[type=submit],
.form-actions .form-submit:not([value=Restore]) {
  background: var(--clr-red-500);
  color: var(--clr-white, #fff);
}
input[type=submit]:hover,
.form-actions .form-submit:not([value=Restore]):hover {
  background: var(--clr-red-600);
}
input[type=submit]:focus,
.form-actions .form-submit:not([value=Restore]):focus {
  box-shadow: 0 0 0 0.25rem var(--clr-red-200);
}

/** Custom form button overrides **/
.form-actions .form-submit[value=Restore],
.form-actions .form-submit[value=Edit],
.form-actions .form-submit[value=Collapse],
.form-actions .form-submit[value*=Add],
input[type=submit][value*=Add] {
  background: var(--clr-black-200);
  color: var(--clr-black-800);
}
.form-actions .form-submit[value=Restore]:hover, .form-actions .form-submit[value=Restore]:focus,
.form-actions .form-submit[value=Edit]:hover,
.form-actions .form-submit[value=Edit]:focus,
.form-actions .form-submit[value=Collapse]:hover,
.form-actions .form-submit[value=Collapse]:focus,
.form-actions .form-submit[value*=Add]:hover,
.form-actions .form-submit[value*=Add]:focus,
input[type=submit][value*=Add]:hover,
input[type=submit][value*=Add]:focus {
  background: var(--clr-black-300);
}

.bg-danger .form-actions .form-submit {
  background: var(--clr-yellow-500, #f9c610);
  color: var(--clr-black-800);
}
.bg-danger .form-actions .form-submit:hover, .bg-danger .form-actions .form-submit:focus {
  background: var(--clr-yellow-600);
}

.bg-danger .block-video-search .form-submit {
  color: var(--clr-black-800);
}

.form-actions .form-submit + a,
.form-actions .form-submit + .form-submit {
  margin-left: 1rem;
}

/*  Checkbox Input
/* -------------------- */
.form-type-checkbox {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
}

input.form-checkbox,
input.form-radio {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  margin-right: 0.25em;
  vertical-align: top;
  background-color: var(--clr-white, #fff);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top-color: rgba(0, 0, 0, 0.25);
  border-right-color: rgba(0, 0, 0, 0.25);
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-left-color: rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border-radius: 0.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
input.form-checkbox:checked,
input.form-radio:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/*  Text Input
/* -------------------- */
input.form-text, textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--clr-black-700, #212529);
  background-color: var(--clr-white, #fff);
  background-clip: padding-box;
  border: 1px solid var(--clr-black-300, #bfbfbf);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input.form-text:focus, textarea:focus {
  color: var(--clr-black-700, #212529);
  background-color: var(--clr-white, #fff);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/*  Webform Components
/* -------------------- */
@media screen and (min-width: 768px) {
  .webform-component--your-details .fieldset-wrapper,
.fieldset-row .fieldset-wrapper {
    display: flex;
    flex-direction: row;
  }
  .webform-component--your-details .fieldset-wrapper .form-item + .form-item,
.fieldset-row .fieldset-wrapper .form-item + .form-item {
    margin-left: 1rem;
  }
}

/*  User profile edit form
/* -------------------- */
/*  Login form + Password Reset
/* ----------------------------- */
form#user-login,
form#user-pass {
  padding: 1rem;
}

/*  Welcome forms
/* -------------------- */
.node-type-webform .content-wrapper {
  background: var(--clr-black-100) !important;
}

.page-node-1348 h1, .page-node-101 h1 {
  height: 0;
  visibility: hidden;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

/*  Video search form
/* -------------------- */
#video-search-form {
  max-width: 40rem;
  margin: 0 auto;
}

.views-exposed-form {
  position: relative;
}

.views-exposed-form::before {
  content: "";
  position: absolute;
  top: -45px;
  left: 45px;
  border: 30px solid var(--clr-black-700, #ced4da);
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-width: 15px;
}

.views-exposed-widgets a {
  text-decoration: none;
  color: var(--clr-black-600, #737373);
  padding-bottom: 0.5rem;
}
.views-exposed-widgets a:hover, .views-exposed-widgets a:focus {
  color: var(--clr-black-700, #404040);
}
.views-exposed-widgets a:focus {
  color: var(--clr-red-500, #e10513);
}

.views-exposed-widgets a.active {
  position: relative;
  color: var(--clr-black-900, #141414);
}
.views-exposed-widgets a.active::after {
  content: "+";
  font-size: 1.5rem;
  line-height: 1;
  transform: rotateZ(45deg);
  display: inline-block;
  position: absolute;
  right: -16px;
  top: -2px;
}

/*  Booking form
/* -------------------- */
#flag-confirm[action*="confirm/flag"]::before {
  content: "Please confirm that you would like to book this slot?";
  font-size: 1.2rem;
  font-weight: bold;
}

#flag-confirm[action*="confirm/unflag"]::before {
  content: "Are you sure you would like to cancel your current booking?";
  font-size: 1.2rem;
  font-weight: bold;
}

img, svg {
  max-width: 100%;
  height: auto;
}

img.exhibitor-logo,
.exhibitor-logo img {
  display: inline-block;
}

.front img.exhibitor-logo,
.exhibitor-logo img {
  max-height: 8rem;
  width: auto;
}

.breadcrumb {
  color: var(--clr-black-500, #666);
}

.breadcrumb .delimiter {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.item-list ul li {
  margin: 0;
}

.item-list .pager li {
  padding: 0;
}

.item-list .pager {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.item-list .pager li a,
.item-list .pager .pager-current,
.item-list .pager .pager-ellipsis {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.item-list .pager li a {
  color: #007bff;
}

/*  Tags
/* -------------------- */
.field-type-taxonomy-term-reference .field-label {
  font-size: 1.25rem !important;
}

.field-type-taxonomy-term-reference ul.links {
  padding-left: 0;
  list-style-type: none;
}

.field-type-taxonomy-term-reference ul.links li {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.field-type-taxonomy-term-reference ul.links a {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem;
  background-color: #f8f9fa;
  color: #212529;
  text-decoration: none;
  border: 1px solid #dee2e6;
}

.form-submit,
.webform-submit {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.webform-submit, .webform-next {
  background: var(--clr-red-500);
  color: var(--clr-white, #fff);
}
.webform-submit:hover, .webform-next:hover {
  background: var(--clr-red-600);
}
.webform-submit:focus, .webform-next:focus {
  box-shadow: 0 0 0 0.25rem var(--clr-red-200);
}

.webform-previous {
  background: var(--clr-black-500);
  color: var(--clr-white, #fff);
}
.webform-previous:hover {
  background: var(--clr-black-600);
}
.webform-previous:focus {
  box-shadow: 0 0 0 0.25rem var(--clr-red-200);
}

.bg-danger .form-actions .webform-submit {
  background: var(--clr-yellow-500);
  color: var(--clr-black-800);
}
.bg-danger .form-actions .webform-submit:hover {
  background: var(--clr-yellow-600);
}
.bg-danger .form-actions .webform-submit:focus {
  box-shadow: 0 0 0 0.25rem var(--clr-yellow-200);
}

/* --------------------
   Exhibitor profile
/* -------------------- */
.tns-controls button + button {
  margin-left: 1rem;
}

button[data-controls=prev],
button[data-controls=next] {
  background: var(--clr-black-200);
  border: 0;
  border-radius: 4px;
  padding: 0.5rem 1.5rem;
}

.tns-nav {
  margin-bottom: 1rem;
}

.tns-nav button {
  height: 1rem;
  width: 1rem;
  display: inline-block;
  border: 1px solid var(--clr-black-200);
  border-radius: 100%;
  padding: 0;
}
.tns-nav button + button {
  margin-left: 0.25rem;
}
.tns-nav button.tns-nav-active {
  background: var(--clr-red-200);
  border-color: var(--clr-red-300);
}

/* --------------------
   Video page
/* -------------------- */
#video-search-form .form-submit {
  background: var(--clr-yellow-500);
  margin-top: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#video-search-form .form-submit:focus {
  box-shadow: 0 0 0 0.25rem var(--clr-yellow-600);
}

@media screen and (min-width: 576px) {
  .exhibitor-logo > * {
    background: var(--clr-white, #fff);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .front .exhibitor-logo {
    height: 8rem;
  }

  .front .exhibitor-logo > * {
    max-width: 90%;
  }
}
/*  Page overrides
/* -------------------- */
.page-node-608 .content-wrapper {
  background: var(--clr-black-100) !important;
}

@media screen and (min-width: 768px) {
  .page-node-608 #site-content .container {
    max-width: 1024px;
  }
}

/*  Task Panels (Individual blocks) 
/* -------------------- */
.task-panel {
  border-radius: 1.25rem;
  background: var(--clr-white);
}

.task-panel--top-only {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.task-panel--bottom-only {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

/*  Task Calendar
/* -------------------- */
.task-calendar--due {
  background: var(--clr-red-100);
  color: var(--clr-red-600);
}
.task-calendar--due:hover, .task-calendar--due:focus {
  background: var(--clr-red-200);
}
.task-calendar--complete {
  background: var(--clr-green-100);
  color: var(--clr-green-600);
}
.task-calendar--complete:hover, .task-calendar--complete:focus {
  background: var(--clr-green-200);
}

[class^=task-calendar] {
  border-radius: 1.25rem;
}

.badge-blue {
  background: var(--clr-blue-100);
  color: var(--clr-blue-800);
}

/*  Container
/* -------------------- */
.page-profile-exhibitor .site-main,
.page-profile-exhibitor .content-wrapper {
  background: var(--clr-black-100) !important;
}
@media screen and (min-width: 768px) {
  .page-profile-exhibitor #site-content .container {
    max-width: 1024px;
  }
}

.profile-panel {
  background: var(--clr-white, #fff);
}

/*  Imnages
/* -------------------- */
.profile-header {
  position: relative;
}

.profile-logo {
  position: absolute;
  bottom: -80px;
  left: 1.5rem;
  background: var(--clr-white, #fff);
  width: 160px;
  height: 160px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--clr-black-300);
  border-radius: 500px;
}

.profile-logo--small {
  height: 4rem;
  width: 4rem;
  background: var(--clr-white, #fff);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--clr-black-300);
  border-radius: 500px;
  overflow: hidden;
}

/*  Content / Typography
/* -------------------- */
.profile-panel h3 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.125rem;
}

.profile-details {
  /* Add spacing so we can offset the absolute position
    of the logo */
  padding-top: 100px !important;
}

.profile2-exhibitor .facebook-icon a,
.profile2-exhibitor .twitter-icon a,
.profile2-exhibitor .linkedin-icon a {
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  overflow: hidden;
  text-indent: -99px;
}
.profile2-exhibitor .facebook-icon a:hover,
.profile2-exhibitor .twitter-icon a:hover,
.profile2-exhibitor .linkedin-icon a:hover {
  background-position: -24px 0;
}
.profile2-exhibitor .facebook-icon a:focus,
.profile2-exhibitor .twitter-icon a:focus,
.profile2-exhibitor .linkedin-icon a:focus {
  background-position: -48px 0;
}

.profile2-exhibitor .facebook-icon a {
  background: url("../../img/icons/facebook.svg") 0 center no-repeat;
  background-size: cover;
}

.profile2-exhibitor .twitter-icon a {
  background: url("../../img/icons/twitter.svg") 0 center no-repeat;
  background-size: cover;
}

.profile2-exhibitor .linkedin-icon a {
  background: url("../../img/icons/linkedin.svg") 0 center no-repeat;
  background-size: cover;
}

.profile2-exhibitor .profile-details [class*=icon] {
  margin-left: 0.5rem;
}

.embed-responsive-item {
  position: relative;
  padding: 56.25% 0 0 0;
}

.embed-responsive-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

th#Thursday,
td[headers=Thursday],
th#Friday,
td[headers=Friday],
th#Saturday,
td[headers=Saturday] {
  display: none;
}

/* Hide footer which appears in colorbox modals*/
#colorbox .region-footer-second {
  display: none;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.calendar-calendar div.calendar {
  font-size: 1rem;
}

.calendar-calendar .week-view .full .calendar-agenda-hour, .calendar-calendar .day-view .full .calendar-agenda-hour {
  padding-right: 0;
  text-align: center;
}

/* Give calendar header a bit more room */
.calendar-calendar th.days {
  padding: 0.75rem;
}

/* Provide some space in dates*/
.calendar-calendar .month-view .full td.single-day .item,
.calendar-calendar .week-view .full td.single-day .item,
.calendar-calendar .day-view .full td.single-day .item {
  padding: 1rem;
}

/* Style the times to make them look pretty */
.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview {
  padding: 1rem;
  background: var(--clr-blue-100, #c7edff);
  border-left: 4px solid var(--clr-blue-200, #94ddff);
}